'use client';

import React, { ReactNode } from 'react';
import Header from '@/app/components/common/Header';
import Footer from '@/app/components/common/Footer';

interface ClientLayoutProps {
  children: ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  headerType?: 'main' | 'back' | 'search' | 'cart';
  title?: string | null;
}

const ClientLayout: React.FC<ClientLayoutProps> = ({
  children,
  showHeader = true,
  showFooter = true,
  headerType = 'main',
  title,
}) => {
  return (
    <>
      {showHeader && <Header type={headerType} title={title} />}
      {children}
      {showFooter && <Footer />}
    </>
  );
};

export default ClientLayout;
