'use client';

import { useState } from 'react';
import { useRouter } from 'next/navigation';
import Link from 'next/link';
import Image from 'next/image';
import Logo from './Logo';
import { useAuth } from '@/app/context/AuthContext';
import { useTranslations } from 'next-intl';

type HeaderType = 'main' | 'back' | 'search' | 'cart';

interface HeaderProps {
  type: HeaderType;
  title?: string | null;
}

const Header: React.FC<HeaderProps> = ({ type, title }) => {
  const t = useTranslations(); 
  const router = useRouter();
  const [menu, setMenu] = useState(false);
  const { isLogin } = useAuth();


  const handleBackClick = () => {
    router.back();
  };

  const handleMenuClick = () => {
    setMenu(!menu);
  };

  const handleCloseMenu = () => {
    setMenu(false);
  };

  return (
    <header className="headerWrap">
      {type === 'main' ? (
        <div className="iconWrap" onClick={handleMenuClick}>
          <Image
            src="/icons/hamburger_menu_icon.svg"
            alt="hamburger_menu"
            width={48}
            height={48}
            priority
          />
        </div>
      ) : (
        <div className="iconWrap" onClick={handleBackClick}>
          <Image
            src="/icons/arrow_back_icon.svg"
            alt="arrow_back"
            width={48}
            height={48}
            priority
          />
        </div>
      )}
      {type === 'main' ? (
        <h1 className="title"><Logo /></h1>
      ) : (
        <h1 className="title">{title ? t(title) : ''}</h1> 
      )}
      <div className="icons">
        {(type === 'main' || type === 'search') && (
          <div className="iconWrap">
            <Image
              src="/icons/search_icon.svg"
              alt="search"
              width={48}
              height={48}
              priority
            />
          </div>
        )}
        {(type === 'main' || type === 'cart') && (
          <div className="cart">
            <Image
              src="/icons/cart_icon.svg"
              alt="cart"
              width={48}
              height={48}
              priority
            />
          </div>
        )}
      </div>
      {menu && (
        <>
          <div className="dim" onClick={handleCloseMenu}></div>
          <nav className="menu">
            <div className="">
              <div className="top">
                <Image
                  src="/icons/close_56.svg"
                  alt="close"
                  className="close"
                  width={56}
                  height={56}
                  onClick={handleCloseMenu}
                />
                <Link href="">
                  <Image
                    src="/icons/search_icon.svg"
                    alt="search"
                    className="search"
                    width={56}
                    height={56}
                  />
                </Link>
              </div>
              <ul className="menuList">
                <li>
                  <Link href="">
                    {t('menu.all')}
                    <Image
                      src="/icons/arrow_56.svg"
                      alt="arrow"
                      width={56}
                      height={56}
                    />
                  </Link>
                </li>
                <li>
                  <Link href="">
                    {t('menu.glucose')}
                    <Image
                      src="/icons/arrow_56.svg"
                      alt="arrow"
                      width={56}
                      height={56}
                    />
                  </Link>
                </li>
                <li>
                  <Link href="">
                    {t('menu.pressure')}
                    <Image
                      src="/icons/arrow_56.svg"
                      alt="arrow"
                      width={56}
                      height={56}
                    />
                  </Link>
                </li>
                <li>
                  <Link href="">
                    {t('menu.weight')}
                    <Image
                      src="/icons/arrow_56.svg"
                      alt="arrow"
                      width={56}
                      height={56}
                    />
                  </Link>
                </li>
                <li>
                  <Link href="">
                    {t('menu.etc')}
                    <Image
                      src="/icons/arrow_56.svg"
                      alt="arrow"
                      width={56}
                      height={56}
                    />
                  </Link>
                </li>
                <li>
                  <Link href="">
                    {t('menu.cs')}
                    <Image
                      src="/icons/arrow_56.svg"
                      alt="arrow"
                      width={56}
                      height={56}
                    />
                  </Link>
                </li>
                {isLogin ? (
                  <li>
                    <Link href="">
                      {t('menu.mypage')}
                      <Image
                        src="/icons/arrow_56.svg"
                        alt="arrow"
                        width={56}
                        height={56}
                      />
                    </Link>
                  </li>
                ) : (
                  <Link href="/auth" className="loginBtn">{t('menu.menu_login')}</Link>
                )}
              </ul>
              <div className="menuFooter">
                <div className="footerSection">
                  <p className="bold">{t('footer.cs')}</p>
                  <p className="bold big">000-0000-0000</p>
                  <p>
                    <Link href="" className="kakao_chanel">
                      <Image
                        src="/icons/kakao_chanel.svg"
                        alt="kakao_chanel"
                        width={32}
                        height={32}
                      />
                      <span>{t('footer.kakao_chanel')} &#39;{t('footer.mall')}&#39;</span>
                    </Link>
                  </p>
                  <div className="subSec">
                    <p>{t('footer.opening_hours01')}</p>
                    <p>{t('footer.opening_hours02')}</p>
                  </div>
                </div>
                <div className="footerSection">
                  <div className="subSec">
                    <p>{t('footer.mobr')}</p>
                    <p>{t('footer.crn')}</p>
                    <p>{t('footer.ceo')}</p>
                    <p>{t('footer.copy')}</p>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </>
      )}
    </header>
  );
};

export default Header;
