'use client';

import { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { useLoadingContext } from '@/app/context/LoadingContext';
import apiClient from '../services/apiClient';
import axios from 'axios';
import Cookies from 'js-cookie';

interface AuthContextProps {
  isLogin: boolean;
  loginType: string | null;
  puserId: number | null;
  login: (loginType: string, puserId: number, accessToken: string, refreshToken: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [loginType, setLoginType] = useState<string | null>(null);
  const [puserId, setPuserId] = useState<number | null>(null);
  const { setLoading } = useLoadingContext();
  const router = useRouter();

  const logout = useCallback(async () => {
    Cookies.remove('refreshToken');
    Cookies.remove('accessToken');
    localStorage.removeItem('loginType');
    localStorage.removeItem('puserId');
    localStorage.removeItem('isLoggedIn');

    setLoginType(null);
    setPuserId(null);
    setIsLogin(false);
    await router.push('/opening'); // 앱일 경우 어디로 보낼지 아직 모르므로 마이페이지 작업 시 분기처리 필요.
  }, [router]);

  useEffect(() => {
    const requestInterceptor = apiClient.interceptors.request.use(
      config => {
        const storedAccessToken = Cookies.get('accessToken');
        const storedLoginType = localStorage.getItem('loginType');
        const storedCountry = localStorage.getItem('country');

        // 플랫폼 정보 설정
        const isApp = typeof window !== 'undefined' && window.Flutter !== undefined;
        const platform = isApp ? 'app' : 'web';
        config.headers['platform'] = platform;

        if (storedAccessToken && storedLoginType) {
          config.headers['Authorization'] = `Bearer ${storedAccessToken}`;
          config.headers['loginType'] = storedLoginType;
        }

        if (storedCountry) {
          config.headers['country'] = storedCountry;
        }

        return config;
      },
      error => Promise.reject(error)
    );

    const responseInterceptor = apiClient.interceptors.response.use(
      response => response,
      error => {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          console.error('인증 실패:', error);
          logout();
        }
        setLoading(false);
        return Promise.reject(error);
      }
    );

    return () => {
      apiClient.interceptors.request.eject(requestInterceptor);
      apiClient.interceptors.response.eject(responseInterceptor);
    };
  }, [logout, setLoading]);

  const checkAuth = useCallback(async () => {
    setLoading(true);
    try {
      const storedAccessToken = Cookies.get('accessToken');
      const storedLoginType = localStorage.getItem('loginType');
      const storedPuserId = localStorage.getItem('puserId');

      if (!storedAccessToken) {
        setLoading(false);
        await router.push('/auth'); 
        return;
      }

      setIsLogin(true);
      setLoginType(storedLoginType);

      if (storedPuserId !== null) {
        setPuserId(parseInt(storedPuserId, 10));
      } else {
        setPuserId(null);
      }

      if (!sessionStorage.getItem('sessionLoggedIn')) {
        sessionStorage.setItem('sessionLoggedIn', 'true');
      }
    } finally {
      setLoading(false);
    }
  }, [router, setLoading]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      checkAuth();
    }
  }, [checkAuth]); 

  const login = (loginType: string, puserId: number, accessToken: string, refreshToken: string) => {
    Cookies.set('accessToken', accessToken);
    Cookies.set('refreshToken', refreshToken);
    localStorage.setItem('loginType', loginType);
    localStorage.setItem('puserId', puserId.toString());
    localStorage.setItem('isLoggedIn', 'true');
    sessionStorage.setItem('sessionLoggedIn', 'true');

    setLoginType(loginType);
    setPuserId(puserId);
    setIsLogin(true);

    const isApp = typeof window !== 'undefined' && window.Flutter !== undefined;

    if (!isApp) {
      router.push('/opening');
      console.log('isapp이 아님');
    }
  };

  return (
    <AuthContext.Provider value={{ isLogin, loginType, puserId, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
