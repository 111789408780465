
import Link from 'next/link';
import { useTranslations } from 'next-intl';

const Footer: React.FC = () => {
    const t = useTranslations(); 
    return (
        <footer className="footerWrap">
            <div className="footerLinks">
                <Link href="/pickeat_intro">
                    {t('footer.pickeat_intro')}
                </Link>
                <Link href="/agreement">
                    {t('footer.agreement')}
                </Link>
                <Link href="/agreement_privacy">
                    {t('footer.agreement_privacy')}
                </Link>
            </div>
            <div className="footerSection">
                <p className="bold">{t('footer.cs')}</p>
                <p className="bold big">000-0000-0000</p>
                <div className="subSec">
                    <p>{t('footer.kakao_chanel')} : {t('footer.mall')}</p>
                    <p>{t('footer.mail')} : pickeatcs@0njourney.com</p>
                </div>
                <div className="subSec">
                    <p>{t('footer.opening_hours01')}</p>
                    <p>{t('footer.opening_hours02')}</p>
                </div>
            </div>
            <div className="footerSection">
                <p className="bold f14">{t('footer.bank')}</p>
                <div className="subSec">
                    <p>{t('footer.bank_txt01')}</p>
                    <p>{t('footer.bank_txt02')}</p>
                </div>
            </div>
            <div className="footerSection">
                <div className="subSec">
                    <p>{t('footer.business_name')}</p>
                    <p>{t('footer.ceo')}</p>
                    <p>{t('footer.address')}</p>
                    <p>{t('footer.mobr')}</p>
                    <p>{t('footer.ask')}</p>
                    <p>{t('footer.copy')}</p>
                </div>
            </div>
        </footer>
    )
};

export default Footer;