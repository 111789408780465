import Image from 'next/image';


const Logo: React.FC = () => {
    return (
        <div className="logoWrap">
            <Image
                src="/icons/pickeat_logo_horizental_v_01.svg"
                alt="pickeat_logo"
                width={122}
                height={44} 
                priority
            />
        </div>
    )
}

export default Logo;