import axios from 'axios'; 
import { Apis } from './apis';

const apiClient = axios.create({
    baseURL : Apis.baseUrl, 
    headers : {
        'Content-Type' : 'application/json',
    }, 
    withCredentials : true,
}); 

export default apiClient;